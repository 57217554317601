import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import RprServiceAreas from '../../../components/RprServiceAreas';

const RealPropertyReports = () => (
  <Layout
    title="Real Property Reports Calgary & Red Deer | Axiom Geomatics"
    description="A Real Property Report (RPR) is a legal document including property dimensions, improvements, permanent features, and more relative to property lines. Axiom Geomatics provides RPR for Calgary and area"
    keywords="real property report, rpr, property survey, house survey, land survey, selling house survey, compliance, letter of compliance, stamp of compliance"
  >
    <main>
      <h1>Real Property Reports Calgary</h1>
      <p>
        A <strong>Real Property Report (RPR)</strong> is a legal document that illustrates the
        location of significant improvements relative to property boundaries. It is a requirement of
        most real estate transactions in Alberta.
      </p>

      <p>An RPR identifies:</p>

      <ul>
        <li>any encroachment from adjacent properties</li>
        <li>all easements, restrictive covenants and right-of-ways</li>
        <li>compliance issues with municipal requirements/bylaws</li>
        <li>problems related to property boundaries</li>
        <li>man-made, above ground permanent features and their relation to property lines</li>
        <li>and much more</li>
        <li>
          Real Property Reports protect property sellers from potential future legal liabilities
          resulting from problems related to property boundaries and improvements.
        </li>
      </ul>

      <p>RPRs protect property buyers with a clear understanding of what they're purchasing.</p>

      <p>
        RPR preparation includes a search for pertinent encumbrances registered against the title, a
        search all plans related to the location of boundaries, a field survey and the preparation
        of a plan/diagram reflecting the results of the survey and title search.
      </p>

      <p>
        Optionally, the completed RPR can be sent to the appropriate municipality to receive a{' '}
        Certificate of Compliance.{' '}
        <Link to="/services/residential-surveys/compliance-costs">
          Fees for this service vary by municipality
        </Link>
        . The Certificate of Compliance is granted to those properties that, based on the RPR
        provided, comply with the local municipal bylaws.
      </p>

      <h2>RPR Cost vs Update Cost</h2>
      <p>
        The amount of work to prepare an RPR varies between properties due to lot size, shape,
        number of buildings, natural features, age and availability of property boundary
        information.
      </p>

      <p>
        If a previous survey has been done on a property, an updated RPR may be created at lesser
        cost, pursuant to a number of restrictions. If you require an RPR update please contact
        Axiom Geomatics to discuss savings opportunities.
      </p>

      <p>
        <Link to="/order-a-real-property-report" className="btn">
          Order a Real Property Report
        </Link>{' '}
        or{' '}
        <Link to="/services/residential-surveys/compliance-costs" className="btn">
          View Certificate of Compliance Costs
        </Link>
      </p>

      <h2>
        <abbr title="Frequently Asked Questions">RPR FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#condo">I'm selling a Condo, do I need a Real Property Report?</Link>
        </li>
        <li>
          <Link to="#rpr-reuse">I have an old Real Property Report, do I need a new one?</Link>
        </li>
        <li>
          <Link to="#compliance">What is compliance?</Link>
        </li>
        <li>
          <Link to="#fence-lines">Can I use my Real Property Report for fence lines?</Link>
        </li>
      </ul>

      <h3 id="condo">I'm selling a Condo, do I need a Real Property Report?</h3>
      <p>
        This can be a complex issue, as there are different types of condominiums. Typically, a
        "traditional" or "building" condominium, often referred to as an apartment style condo, does
        not need a Real Property Report. However, a "bare land" condo typically will require a{' '}
        <strong>Real Property Report</strong>. Please contact us with the specific details of your
        property for further information.
      </p>

      <h3 id="rpr-reuse">I have an old Real Property Report, do I need a new one?</h3>
      <p>
        Generally, old surveys will need to be updated. A Real Property Report is typically
        considered valid, as long as it currently represents the property correctly. There are two
        common ways a Real Property Report can become invalid:
      </p>

      <ol>
        <li>
          The size or location of structures on the property have changed since the existing Real
          Property Report was done. Typical changes include additions, alterations or removal of
          fences, sheds, decks and garages.
        </li>
        <li>
          The municipality changes their RPR content requirements. This can be an almost annual
          occurrence. Due to these changing requirements, and despite no changes whatsoever
          affecting the property, the Real Property Report may be invalid.
        </li>
      </ol>

      <h3 id="compliance">What is compliance?</h3>
      <p>
        A "stamp of compliance" is provided for a fee at most municipalities. The stamp that the
        municipality places on the Real Property Report indicates that the property complies with
        those municipal by-laws that relate to the Real Property Report. If you encounter problems
        while obtaining a stamp of compliance, please contact Axiom Geomatics for assistance.
      </p>

      <h3 id="fence-lines">Can I use my Real Property Report for fence lines?</h3>
      <p>
        It is not recommended that an Real Property Report be used to establish a property line. If
        you require a boundary for fence line construction, or any other purpose, please request a
        Property Line Survey. The survey work for an original Real Property Report and
        <a href="https://axiomgeomatics.ca/services/residential-surveys/property-line-surveys">
          fence line survey
        </a>{' '}
        can be performed simultaneously resulting in savings of both time and money.
      </p>

      <p>
        <Link to="/order-a-real-property-report" className="btn">
          Order a Real Property Report
        </Link>
      </p>
    </main>

    <aside>
      <RprServiceAreas />
    </aside>
  </Layout>
);

export default RealPropertyReports;
