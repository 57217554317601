import React from 'react';
import '../styles/table.css';

const cities = [
  'Airdrie',
  'Beiseker',
  'Black Diamond',
  'Black Falds',
  'Bragg Creek',
  'Brooks',
  'Calgary',
  'Canmore',
  'Carseland',
  'Carstairs',
  'Chestermere',
  'Clearwater County',
  'Cochrane',
  'Crossfield',
  'Didsbury',
  'Drayton Valley',
  'Drumheller',
  'Ghost Lake',
  'High River',
  'Innisfail',
  'Irricana',
  'Lacombe',
  'Langdon',
  'Lethbridge',
  'Longview',
  'MD Bighorn (No 8)',
  'MD Foothills (No 31)',
  'Mountian View County',
  'Nanton',
  'Okotoks',
  'Olds',
  'Ponoka',
  'Priddis',
  'Red Deer',
  'Rocky Mountain House',
  'Rocky View County',
  'Stettler',
  'Strathmore',
  'Sundre',
  'Sylvan Lake',
  'Taber',
  'Three Hills',
  'Turner Valley',
  'Vulcan County',
  'Wataskiwin',
  'Wheatland County',
];

const RprServiceAreas = () => (
  <table className="table-price">
    <caption>RPR Service Areas</caption>
    <tbody>
      {cities.map((city) => (
        <tr key={city}>
          <td>{city}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default RprServiceAreas;
